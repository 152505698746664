@import "minima";

.site-title::before {
    content: url('/assets/img/logo.png');
    display: inline-block;
    position: relative;
    top: 16px;
    margin-right: 1rem;
}

.page-link {
    color: $text-color;
    line-height: $base-line-height;
    vertical-align: bottom;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
  
img.top-right {
    margin: 1rem;
    width: 12rem;
}

@media (min-width: 402px) {
    img.float-right {
        float: right;
    }
}

a.float-right {
    float: right;
}
